import { ExtrudeGeometry } from 'three';

class TextGeometry extends ExtrudeGeometry {
  constructor(text, parameters) {
    const {
      bevelEnabled = false,
      bevelSize = 8,
      bevelThickness = 10,
      font,
      height = 50,
      size = 100,
      ...rest
    } = parameters;
    const shapes = font.generateShapes(text, size);
    super(shapes, { ...rest,
      bevelEnabled,
      bevelSize,
      bevelThickness,
      depth: height
    });
    this.type = 'TextGeometry';
  }

}

export { TextGeometry as TextBufferGeometry, TextGeometry };
